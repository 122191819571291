@import "./variables.scss";

div[data-page="promotion-detail"] {
  min-height: calc(100vh - 182px);

  .promotion-badge {
    text-align: center;
    padding: 3px 10px;
    font-size: 13px;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
  }

  .gallery {
    img {
      border-radius: 10px;
      border: 1px solid $border;
    }

    .thumbnail {
      flex-shrink: 0;
      border-radius: 10px;
      width: 50px;
      height: 50px;
      background-position: center;
      background-size: cover;
      border: 1px solid $border;
    }
  }

  .price-container {
    background-color: rgba(195, 3, 3, 0.05);
    padding: 10px;
    border-radius: 10px;
    text-align: center;

    .price {
      font-size: 24px;
      margin-top: -10px;
      color: #FE6160 !important;
    }

    .discount-price {
      font-size: 18px;
      color: #707070 !important;
    }
  }

  .merchant {
    border-radius: 10px;
    margin-top: 20px;
    border: 1px solid $border;

    .merchant-info {
      border-bottom: 1px solid $border;
      padding: 10px 50px;
      font-size: 14px;
      display: flex;
      align-items: center;

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    .promotion-content,
    .gallery {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .merchant-info {
      padding: 10px 20px !important;
    }

    .mt-5 {
      margin-top: 20px !important;
    }

    h4 {
      font-size: 1.25rem !important;
    }

    h5 {
      font-size: 1rem !important;
    }
  }
}