@import "./variables.scss";

div[data-page="categories"] {
  min-height: calc(100vh - 182px);
  padding-top: 20px;

  a.active {
    font-weight: bold;
  }

  .navigation button {
    background-color: $primary-color;

    &:focus {
      border-color: $primary-color;
      outline: none;
    }
  }
}

.modal-content {
  width: 250px !important;
  margin: 0 auto;

  a {
    display: block;
    padding: 3px 10px;

    &:active {
      background-color: $primary-color;

      p {
        color: #fff !important;
      }
    }
  }
}