@import "./variables.scss";

div[data-page="Home"] {
  .categories {
    img {
      border-radius: 10px;
    }

    .category-img, .placeholder-img {
      border-radius: 10px;
      width: 100px;
      height: 100px;
      background-color: $placeholder;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }

    .placeholder-text {
      width: 70px;
      height: 15px;
      margin: 0 auto;
      margin-top: 14px;
      border-radius: 5px;
      margin-bottom: 1rem;
      background-color: $placeholder;
    }
  }

  .bg-gray {
    background-color: rgba(112, 112, 112, 0.06);
  }

  .category-promotions {
    .placeholder-icon {
      width: 25px;
      height: 25px;
      border-radius: 5px;
      background-color: $placeholder;
    }

    .placeholder-title {
      width: 150px;
      height: 18px;
      border-radius: 5px;
      background-color: $placeholder;
    }

    .promotion-row {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-right: calc(-.5 * var(--bs-gutter-x));
      margin-left: calc(-.5 * var(--bs-gutter-x));
    }

    .promotion-col {
      flex: 0 0 auto;
      width: 50%;
    }

    .promotion-row > * {
      flex-shrink: 0;
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
      margin-top: var(--bs-gutter-y);
    }

    .image-wrapper .available-promotion {
      position: absolute;
      top: 0;
    }
  }
}
