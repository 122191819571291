@import "./variables.scss";

.top-header {
  padding-top: 5px;
  padding-bottom: 5px;
  // border-bottom: 1px solid $border;
  background-color: $primary-color;

  .contact-container {
    display: flex;
    flex-direction: row;
    height: 30px;
  }

  .contact {
    display: flex;
    align-items: center;
    margin-right: 40px;

    span,
    span a {
      font-size: 13px;
      text-decoration: none;
      // color: $font;
      font-weight: 500;
      color: white;
    }

    svg {
      margin-right: 5px;

      path {
        color: white;
      }
    }
  }

  .social-media-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 40px;
    height: 30px;
    align-items: center;
  }

  .social-media-wrapper {
    width: 25px;
    height: 25px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;
    background-color: white;
  }

  @media (max-width: 1023px) {
    .contact-container,
    .social-media-container {
      justify-content: center;
      margin-right: 0;
    }

    .contact {
      margin-right: 20px;
    }
  }

  @media (max-width: 510px) {
    .contact svg {
      margin-right: 3px;
    }
  }
}

.top-nav {
  input:focus, button:focus {
    box-shadow: none;
    border-color: $secondary-color;
  }

  input {
    border-radius: 10px;
  }

  button {
    background-color: $secondary-color;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: $secondary-color;

    svg path {
      color: #fff;
    }

    &:active, &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;

      outline: none;
    }
  }
}

.footer {
  background-color: $dark-gray;
  border-top: 1px solid #dedede;
  margin-top: 50px;
  color: #fff;

  .title {
    font-size: 16px;
  }

  .content {
    padding-top: 30px;
    padding-bottom: 30px;

    p, a {
      color: #fff;
    }

    a {
      display: block;
      text-decoration: none;
    }
  }

  .logo-wrapper {
    background-color: #fff;
    width: 150px;
    height: 150px;
    border-radius: 75px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 992px) {
      width: 120px;
      height: 120px;
      border-radius: 60px;

      img {
        width: 80px !important;
      }
    }
  }

  .about-us {
    flex: 1;
    margin-left: 50px;

    @media (max-width: 992px) {
      margin-left: 20px;

      p {
        padding-right: 20px !important;
      }
    }

    p {
      color: #fff;
    }
  }

  .all-right-reserved {
    margin-bottom: 0;
    padding: 30px;
    color: #fff;
    text-align: center;
    background-color: $dark;
  }

  @media (max-width: 992px) {
    .content {
      .image {
        width: 80px !important;
      }
    }

    .company, .contact-us {
      p, a {
        text-align: center;
      }
    }

    p.title {
      margin-top: 30px;
    }
  }
}

.promotion-item {
  p {
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .promotion-badge {
    text-align: center;
    position: absolute;
    top: 5px;
    left: 5px;
    padding: 3px 10px;
    font-size: 13px;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    max-width: calc(100% - 10px);
  }

  .image-wrapper {
    width: 100%;
    padding-top: 100%;
    border-radius: 10px;
    position: relative;
    background-color: $placeholder;

    div {
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;
      background-position: center;
      background-size: cover
    }
  }

  .placeholder-text {
    height: 15px;
    width: 100px;
    margin: 0 auto;
    margin-top: 14px;
    border-radius: 5px;
    background-color: $placeholder;
  }
}

.discount {
  background-color: $discount-promotion;
}

.bogo {
  background-color: $bogo-promotion;
}

.cashback {
  background-color: $cashback-promotion;
}

.other {
  background-color: $other-promotion;
}

.not-found {
  min-height: calc(100vh - 474px);
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .not-found-code {
    font-weight: bold;
    font-size: 100px;
    margin-bottom: 0;
  }

  .not-found-message {
    font-size: 40px;
    margin-top: -25px;
  }

  button {
    background-color: $primary-color;
  }
}
