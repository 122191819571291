$primary-color: #003366;
$secondary-color: #c20000;
$light-gray: #f0f0f0;
$shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
$font: #363636;
$border: #dedede;
$placeholder: #dedede;

$dark-gray: #707070;
$dark: #363636;

$discount-promotion: #d80003;
$bogo-promotion: #e34585;
$cashback-promotion: #03b0af;
$other-promotion: #3b83b3;
