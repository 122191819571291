@use "./styles/global.scss";
@use "./styles/home.scss";
@use "./styles/categories.scss";
@use "./styles/promotion.scss";

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, a {
  font-size: 14px;
}

* {
  font-family: "Montserrat" !important;
  color: #363636;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.khmer-bold {
  font-family: 'Nokora', sans-serif !important;
  font-weight: 700;
}

.khmer {
  font-family: 'Nokora', sans-serif !important;
}

.clickable {
  cursor: pointer;
}

.break-line {
  white-space: pre-line;
}
